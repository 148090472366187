import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Img from 'gatsby-image'
import WorkSection from '../components/WorkSection'


export const WorkPostTemplate = ({
  helmet,
  contentComponent,
  description,
  title,
  url,
  featuredimage,
  catchCopy,
  clientName,
  clientPhoto,
  comment,
  initiativeTitles,
  initiativeDescriptions
}) => {
  const PostContent = contentComponent || Content
  
  return (
    <section>
      <div className="l-container">
        {helmet || ''}
        <h1 className="c-pageTitle" data-spacingsp="mb7">
          <span className="c-pageTitle__container">
            <span className="c-pageTitle__textJa">開発事例<br />{title}様</span>
            <span className="c-pageTitle__textEn">WORKS</span>
          </span>
        </h1>
      </div>
      <div className="l-container--wide">
        <ul className="p-breadCrumbs">
          <li className="p-breadCrumbs__item"><Link to="/" className="p-breadCrumbs__link">ホーム</Link></li>
          <li className="p-breadCrumbs__item"><Link to="/work" className="p-breadCrumbs__link">開発事例</Link></li>
          <li className="p-breadCrumbs__item isCurrent">{title}</li>
        </ul>
      </div>
      <div className="l-container">
        <section className="p-work--single">
          <div className="p-work__container--single">
            <header className="p-work__header u-alignCenter">
              {featuredimage ? (
                <div className="p-work__logo--single">
                  <Img
                    alt={title}
                    key={featuredimage}
                    fluid={featuredimage.childImageSharp.fluid}
                    imgStyle={{ objectFit: 'contain'}}
                  />
                </div>
              ) : null}
              <h2 className="p-work__catchCopy" data-spacing="pv3 mb0">
                {catchCopy}
              </h2>
            </header>
            <p className="p-work__lead">
              {description}
            </p>
            <p className="u-alignCenter" data-spacing="mb8 pt5 pb2">
              <a
                className="p-work__link"
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                ［ {title}様ウェブサイトはこちらから ］
              </a>
            </p>
            <article className="p-clientComment">
              <header className="p-clientComment__header">
                <figure className="p-clientComment__figure">
                  <Img
                    alt=""
                    key={clientPhoto}
                    fluid={clientPhoto.childImageSharp.fluid}
                    />
                </figure>
                <span className="p-clientComment__name">
                  {clientName}
                </span>
              </header>
              <div className="p-clientComment__body">
                {(() => {
                  //イントロ部分 ループ処理
                  const items = []
                  for (let i = 0; i < initiativeTitles.length; i++) {
                    items.push(
                      <li key={initiativeTitles[i]}>
                        <h3 className="p-clientComment__title">{initiativeTitles[i]}</h3>
                        <p className="p-clientComment__copy">{initiativeDescriptions[i]}</p>
                      </li>
                    )
                  }
                  return <ul>{items}</ul>
                  
                })()}
              </div>
            </article>

            {comment.comment1.title ? (
              <WorkSection
                title={comment.comment1.title}
                imgSrc={clientPhoto}
                fluid={clientPhoto.childImageSharp.fluid}
                copy={comment.comment1.description}
              />
            ) : null}
            {comment.comment2.title ? (
              <WorkSection
                title={comment.comment2.title}
                imgSrc={clientPhoto}
                fluid={clientPhoto.childImageSharp.fluid}
                copy={comment.comment2.description}
              />
            ) : null}
            {comment.comment3.title ? (
              <WorkSection
                title={comment.comment3.title}
                imgSrc={clientPhoto}
                fluid={clientPhoto.childImageSharp.fluid}
                copy={comment.comment3.description}
              />
            ) : null}
            {comment.comment4.title ? (
              <WorkSection
                title={comment.comment4.title}
                imgSrc={clientPhoto}
                fluid={clientPhoto.childImageSharp.fluid}
                copy={comment.comment4.description}
              />
            ) : null}
            {comment.comment5.title ? (
              <WorkSection
                title={comment.comment5.title}
                imgSrc={clientPhoto}
                fluid={clientPhoto.childImageSharp.fluid}
                copy={comment.comment5.description}
              />
            ) : null}


            <p className="u-alignCenter" data-spacing="pt3">
              <Link className="c-button--skewYellow c-workSection__button" to="/service/">
                アーバのサービス
                <span className="c-icon--arrow c-button__arrow" />
              </Link>
            </p>
          </div>
        </section>
      </div>


    </section>
  )
}

WorkPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  date: PropTypes.string,
  featuredimage: PropTypes.object,
  featuredpost: PropTypes.string,
  clientPhoto: PropTypes.object,
  clientName: PropTypes.string,
}

const WorkPost = ({ data }) => {
  const { markdownRemark: post } = data
  let schemaOrgJSONLD = [
    {
      '@context': 'https://schema.org',
      '@type': 'Corporation',
      '@id': "https://arbor.jp",
      url: "https://arbor.jp",
      name: "株式会社アーバ",
      alternateName: "株式会社アーバ",
      address: {
        '@type': 'PostalAddress',
        'postalCode': '372-0012',
        'addressCountry': 'JP',
        'addressRegion': '群馬県',
        'addressLocality': '伊勢崎市',
        'streetAddress': '豊城町2144−3',
      },
      founder: {
        '@type': 'Person',
        'givenName': '直人',
        'familyName': '石丸',
      },
      foundingDate: '2018-1-11',
      description: post.frontmatter.description,
    },
    {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          name: "トップページ",
          position: 1,
          item: {
            "@id": "https://arbor.jp",
            "name": "株式会社アーバ",
          }
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            "@id": typeof window !== "undefined" && window.location.href,
            "name": post.frontmatter.title,
          }
        },
      ],
    },
  ]; 

  return (
    <Layout>
      <WorkPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s様開発事例| 株式会社アーバ">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        url={post.frontmatter.url}
        catchCopy={post.frontmatter.catchCopy}
        featuredimage={post.frontmatter.featuredimage}
        clientName={post.frontmatter.clientName}
        clientPhoto={post.frontmatter.clientPhoto}
        initiative={post.frontmatter.initiative}
        comment={post.frontmatter.comment}
        initiativeTitles={post.frontmatter.initiative.title}
        initiativeDescriptions={post.frontmatter.initiative.description}
      />
    </Layout>
  )
}

WorkPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default WorkPost

export const pageQuery = graphql`
  query WorkPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        url
        catchCopy
        featuredpost
        featuredimage {
          childImageSharp {
            fluid( maxHeight: 100, quality: 85) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        clientName
        clientPhoto {
          childImageSharp {
            fluid( maxHeight: 270, quality: 85) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        initiative{
          title
          description
        }
        comment{
          comment1{
            title
            description
          }
          comment2{
            title
            description
          }
          comment3{
            title
            description
          }
          comment4{
            title
            description
          }
          comment5{
            title
            description
          }
        }

      }
    }
  }
`
